.searchdropdown {
    position: absolute;
    inset: 98px 0 0 0;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    z-index: 999;
    max-height: 240px;
    max-width: 300px;
    overflow-y: scroll;
    background-color: white;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px 0px;
  }
  
  .searchdropdown > a {
    padding: 10px;
    color: #363636;
    /* border-bottom: 1px solid grey; */
    background-color: white;
  }
  
  .searchdropdown > a:hover {
    background-color: lightblue;
  }