.autocomplete {
    position: relative;
    width: 100%;
    max-width: 472px;
    padding-bottom: 5px;
  }
  
  .suggestions-container {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 1000;
  }
  
  .arrow {
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #fff;
    position: absolute;
    top: 1px;
    left: 20px;
    filter: drop-shadow(0 -1px 1px rgba(0,0,0,0.1));
  }
  
  .suggestions {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    list-style-type: none;
    padding: 0;
    margin: 8px 0 0 0;
    width: 100%;
    max-height: 160px;
    overflow-y: auto;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .suggestions li {
    padding: 8px 12px;
    cursor: pointer;
    white-space: normal;
  }
  
  .suggestions li.active {
    background-color: #f0f0f0;
  }
  
  .autocomplete input,
  .autocomplete textarea {
    width: 100%;
    padding: 10px 10px 10px 16px;
    border-radius: 12px;
    border: 1px solid #E3E7EF;
    outline: none;
    font-family: inherit;
    font-size: 14px;
  }
  
  .autocomplete textarea {
    height: 90px;
    resize: none;
  }