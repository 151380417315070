@import url("https://fonts.googleapis.com/css2?family=Gotham:wght@500&display=swap");
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("./assets/fonts/Proxima Nova Light Italic.otf") format("opentype");
  font-weight: normal; /* Light */
  font-style: italic;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("./assets/fonts/Proxima Nova Extrabold.otf") format("opentype");
  font-weight: bolder; /* Extrabold */
  font-style: normal;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("./assets/fonts/Proxima Nova Light.otf") format("opentype");
  font-weight: 300; /* Light */
  font-style: normal;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("./assets/fonts/Proxima Nova Regular Italic.otf") format("opentype");
  font-weight: 400; /* Regular */
  font-style: italic;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("./assets/fonts/Proxima Nova Regular.otf") format("opentype");
  font-weight: 400; /* Regular */
  font-style: normal;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("./assets/fonts/Proxima Nova Semibold.otf") format("opentype");
  font-weight: 600; /* Semibold */
  font-style: normal;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("./assets/fonts/Proxima Nova Semibold.otf") format("opentype");
  font-weight: 600; /* Semibold */
  font-style: normal;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("./assets/fonts/Proxima Nova Thin Italic.otf") format("opentype");
  font-weight: 100; /* Thin */
  font-style: italic;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("./assets/fonts/Proxima Nova Thin.otf") format("opentype");
  font-weight: 100; /* Thin */
  font-style: normal;
}
html,
body,
#root,
.app {
  background-color: #f7f9fc;
}



.custom-toast{
  width: 446px;
  min-height: 66px;
  padding: 0 !important;
  box-sizing:  border-box;
  border-radius: 9px !important;
}


.custom-body {
  margin: 0 !important;
  padding: 0 !important;
  display: flex;
  align-items: center;
}






