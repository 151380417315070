/* Styles for the 'container' class */
.container {
  width: 100%;
  height: 85dvh;
  overflow-y: scroll;
  display: flex;
  box-sizing: border-box;
}

.container {
  width: 100%;
  /* min-height: 100vh; */
  position: relative;
  margin: 0 auto;
}

/* Styles for the 'messagesBody' class */
.messagesBody {
  width: calc(100% - 20px);
  margin: 10px;
  overflow-y: scroll;
  /* height: calc(100% - 80px); */
}

/* message  */
/* Styles for messageRow class */
.messageRow {
  display: flex;
  margin-bottom: 28px;
  box-sizing: border-box;
}

/* Styles for messageRowRight class */
.messageRowRight {
  display: flex;
  margin-left: auto;
  justify-content: flex-end;
  margin-bottom: 28px;
  box-sizing: border-box;
}

.messagesBody,
.container {
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent; /* for Firefox */
}

.messagesBody::-webkit-scrollbar,
.container::-webkit-scrollbar {
  width: 0px; /* width of the scrollbar */
}

.messagesBody::-webkit-scrollbar-thumb,
.container::-webkit-scrollbar-thumb {
  background-color: transparent; /* color of the thumb */
}

.messagesBody::-webkit-scrollbar-track,
.container::-webkit-scrollbar-track {
  background-color: transparent; /* color of the track */
}

/* message left */

.detailContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

/* show images */

.cuts > div {
  width: 0;
  height: 0;
  border-left: 5px solid transparent; /* Adjust size as needed */
  border-right: 5px solid transparent; /* Adjust size as needed */
  border-bottom: 8.3px solid #f6f8fa;
  transform: rotate(150deg);
}

.cuts {
  position: absolute;
  right: -5px;
  top: 50%;
  transform: translateY(-50%);
}

.ticket {
  position: relative;
  height: 30px;
  padding: 5px;
  box-sizing: border-box;
  display: inline-flex;
  min-width: 60px;
  justify-content: center;
  align-items: center;
  background: #ffc940;
  overflow: hidden;
  max-width: 225px;
}
