.searchbar {
  width: 273px;
  height: 30px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  border: 1px solid #e0e2e7;
  box-sizing: border-box;
  background: #fff;
  border-radius: 8px;
  gap: 10px; /* Adds spacing between icon and input */
}

.searchbar-input {
  flex: 1;
  border: none;
  background-color: inherit;
  outline: none;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

.searchbar-input::placeholder {
  font-family: Proxima Nova;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #858D9D;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.searchbar-icon {
  width: 12px; /* Adjust size based on your icon */
  height: 12px;
  color: #858d9d; /* Adjust color based on design */
}
